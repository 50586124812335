import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import telaAssinador from "@/views/sign/signer/index.vue";

const routes: Array<RouteRecordRaw> = [
  {
    name: "signer",
    path: "/signature/envelope/:hashId",
    component: telaAssinador,
  },
  {
    name: "sign",
    path: "/:passEnvelopeId/:requestSignId",
    component: telaAssinador,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
