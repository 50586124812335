const { GENERIC_MUTATION } = types;
import { ActionTree } from "vuex";

import types from "./types";

const setShowModal = ({ commit }: any, value: boolean) =>
  commit(GENERIC_MUTATION, {
    property: "showModalState",
    value: value,
  });

const setEmailUsuario = ({ commit }: any, value: string) =>
  commit(GENERIC_MUTATION, {
    property: "email",
    value,
  });

const setLogo = ({ commit }: any, value: string) =>
  commit(GENERIC_MUTATION, {
    property: "logo",
    value,
  });

export default <ActionTree<any, any>>{ setShowModal, setEmailUsuario, setLogo };
