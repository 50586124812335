import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { alertController, AlertOptions, toastController } from "@ionic/vue";
import { ErrorResponse } from "./responses/ErrorResponse";
import { closeCircle } from "ionicons/icons";
import router from "@/router";

export abstract class HTTPBaseService {
  protected instance: AxiosInstance;
  protected readonly baseURL: string;

  public constructor(baseURL: string) {
    this.baseURL = baseURL;
    this.instance = axios.create({
      baseURL,
    });

    this.initializeRequestInterceptor();
    this.initializeResponseInterceptor();
  }

  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(this.handleRequest);
  };

  private initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use((response) => {
      return response;
    }, this.handleError);
  };

  private handleRequest = (config: AxiosRequestConfig | any) => {
    if (localStorage.getItem("token"))
      config!.headers["Authorization"] = `Bearer ${atob(
        localStorage.getItem("token") as any
      )}`;
    return config;
  };

  private handleError = async (error: AxiosError<ErrorResponse>) => {
    
    if (error.response?.status === 401) {
      router.push({ name: "autenticador" });
    } else if (error.response?.status === 400) {
      for (const iterator of error.response.data.notifications) {
        await this.openToastOptions(
          error.response.data.title,
          iterator.message
        );
      }
      throw new Error(error.response?.statusText);
    } else if (error.response?.status === 500) {
        const testUrl = error.request.responseURL.indexOf(process.env.VUE_APP_ROOT_API_SHAR + "/archive") != -1
        if (!testUrl) {
            await this.openToastOptions(
              "Falha",
              "Erro interno do servidor"
            );
            throw new Error(error.response?.statusText);
        } else {
            throw new Error('file not found');
        }
    }
  };

  async presentAlert(option: AlertOptions) {
    const alert = await alertController.create(option);
    await alert.present();
  }

  async openToastOptions(header: string, message: string) {
    const toast = await toastController.create({
      header: header,
      message: message,
      icon: closeCircle,
      animated: true,
      duration: 2000,
      position: "bottom",
      color: "danger",
    });

    await toast.present();
    await toast.onDidDismiss();
  }

  getLocation(setPosition: (position: any) => void): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setPosition(position);
        },
        () => {
          setPosition(null);
        }
      );
    }
  }
}
