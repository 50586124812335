
import dayjs from "dayjs";
import { defineComponent } from "vue";
import { IonInput, IonIcon, IonDatetime, IonModal } from "@ionic/vue";
import { eye, eyeOffOutline } from "ionicons/icons";

export default defineComponent({
  name: "SignInput",
  components: {
    IonInput,
    IonIcon,
    IonDatetime,
    IonModal,
  },
  setup() {
    return {
      eye,
      eyeOffOutline,
    };
  },

  props: {
    name: String,
    text: String,
    type: String,
    hasIcon: Boolean,
    required: Boolean,
  },

  data: () => ({
    value: null,
    show: false,
    openModal: false,
    date: null,
    data: "",
  }),

  watch: {
    date() {
      this.data = dayjs(this.date).format("DD/MM/YYYY");
      this.$emit("value", this.data);
    },
  },
});
