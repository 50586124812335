
import { defineComponent } from "vue";
import { IonIcon } from "@ionic/vue";

export default defineComponent({
  name: "SignDropdown",
  components: {
    IonIcon,
  },

  props: {
    max: Number,
    atual: Number,
  },

  data: () => ({
    index: 1,
  }),
});
