import { AxiosResponse } from "axios";
import { HTTPBaseService } from "./HTTPBaseService";
import { LoginCommandRequest } from "./requests/LoginCommandRequest";
import { LoginCommandResponse } from "./responses/LoginCommandResponse";

export class LoginService extends HTTPBaseService {
  private static classInstance?: LoginService;

  constructor() {
    super(process.env.VUE_APP_ROOT_API_SHAR);
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new LoginService();
    }

    return this.classInstance;
  }

  public login = async (
    request: LoginCommandRequest
  ): Promise<LoginCommandResponse | null> => {
    request.notificationKey = "";
    const response: AxiosResponse<LoginCommandResponse> =
      await this.instance.post<LoginCommandResponse>(
        "/core/api/v1/users/authenticate",
        request
      );
    if (response) return response.data;
    return null;
  };
}
