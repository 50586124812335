import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13ed368d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "viewer" }
const _hoisted_2 = { class: "viewer-down" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DownloadOutlined = _resolveComponent("DownloadOutlined")!
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.isMobile)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.downDoc(_ctx.url, _ctx.titleFile))),
            class: "doc-down"
          }, [
            _createVNode(_component_DownloadOutlined),
            _createTextVNode(" Salvar")
          ]))
        : _createCommentVNode("", true),
      (_ctx.isMobile)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.downDoc(_ctx.url, _ctx.titleFile))),
            class: "doc-down"
          }, [
            _createVNode(_component_EyeOutlined),
            _createTextVNode(" Visualizar Documento")
          ]))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.isMobile)
      ? (_openBlock(), _createElementBlock("iframe", {
          key: 0,
          height: "100%",
          width: "100%",
          src: `${_ctx.url}#toolbar=0&navpanes=0`
        }, " ", 8, _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}