const { GENERIC_MUTATION } = types;
import { ActionTree } from "vuex";

import types from "./types";

const setCadastroPessoaJuridica = ({ commit }: any, value: unknown) =>
  commit(GENERIC_MUTATION, {
    property: "cadastroPessoaJuridica",
    value,
  });

export default <ActionTree<any, any>>{ setCadastroPessoaJuridica };
