
import { defineComponent } from "vue";

export default defineComponent({
  name: "SignPaginate",

  props: {
    totalItems: Number,
    qtdPerPage: Number,
    tamAtual: Number,
  },

  data: () => ({
    open: "<",
    close: ">",
    index: 1,
    atual: 1,
  }),

  watch: {
    atual() {
      this.$emit("item-atual", this.atual);
    },
  },

  computed: {
    pages() {
      const totalFloat = (this.totalItems || 1) / (this.qtdPerPage || 1);
      const total = Math.floor((this.totalItems || 1) / (this.qtdPerPage || 1));
      return total < totalFloat ? total + 1 : total;
    },
  },
});
