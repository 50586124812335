import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21377a16"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "header" }
const _hoisted_2 = { class: "header-wrapper" }
const _hoisted_3 = { class: "logo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_image = _resolveComponent("a-image")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("section", _hoisted_3, [
        _createVNode(_component_a_image, {
          src: _ctx.getLogo(),
          preview: false,
          onClick: _cache[0] || (_cache[0] = () => _ctx.redirectApp())
        }, null, 8, ["src"])
      ])
    ])
  ]))
}