
import { defineComponent, ref, h } from "vue";
import { notification, Modal } from 'ant-design-vue'
import {
  HistoryOutlined,
  FileTextOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
  SafetyCertificateOutlined,
  SolutionOutlined,
  WalletOutlined,
} from "@ant-design/icons-vue"
import {
  IonPage,
  IonButton,
  IonModal,
  toastController,
  IonLoading
} from "@ionic/vue"
import { closeCircle, checkmarkCircleOutline } from "ionicons/icons"
import { LoginService } from "@/services/LoginService"
import { EnvelopeService } from "@/services/EnvelopeService"
import { PaperSignatureService } from "@/services/PaperSignatureService"
import { TokenService } from "@/services/TokenService"
import { UserService } from "@/services/UserService"
import { ArchiveService } from "@/services/ArchiveService"
import { SignInput, SignCheckbox, PDFViewer } from "@/components"
import Header from "@/components/Header.vue"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import { CpfCommandRequest } from "@/services/requests/CpfCommandRequest"

dayjs.extend(customParseFormat)  

export default defineComponent({
  name: "subscriberCertificata",
  components: {
    IonPage,    
    IonButton,
    IonModal,    
    IonLoading,
    Header,
    SignInput,
    SignCheckbox,
    PDFViewer,
    HistoryOutlined,
    FileTextOutlined,
    CheckCircleOutlined,
    InfoCircleOutlined,
    CloseCircleOutlined,
    ClockCircleOutlined,
    // SafetyCertificateOutlined,
    SolutionOutlined,
    WalletOutlined
  },
  data: () => ({
    services: null as EnvelopeService | null,
    paperSignatureServices: null as PaperSignatureService | null,
    loginService: null as LoginService | null,
    signer: null as any,
    subscribers: [] as any,
    historico: [] as any,
    papersSignature: [] as any,
    requestId: null as any,
    hashId: null as any,
    passEnvelopeId: null as any,
    requestSignId: null as any,
    rejectDocument: false as boolean,    
    fileUrl: null as any,
    user: false as boolean,
    document: "" as string,
    name: "" as string,
    password: "" as string,
    geolocation: "" as any,    
    title: "" as any,    
    folderPath: "" as string,
    isOpenModal: false,
    canDismiss: false,
    handlerMessage: "",
    loading: false,
    isOrder: false,
    dateSendEnvelope: "" as string,
    isAcceptReject: ref<boolean>(false),
    isViewer: ref<boolean>(true),
    isMobile: ref<boolean>(false),
    isInfo: ref<boolean>(false)    
  }),
  async mounted() {
    await this.loadInformation();  
    this.loginService = LoginService.getInstance();
    this.loginService.getLocation(this.setPosition);
    
    if (window.innerWidth < 768) {
      this.isMobile = true
      this.isInfo = false
      this.isViewer = true
    } else {
      this.isMobile = false
      this.isInfo = true
      this.isViewer = true
    }
  },
  computed: {
    signerName() {
      return this.signer?.partName;
    },
    statusSigner() {
      return this.signer?.status == 1;
    },
    url(): any {
      return this.fileUrl;
    },
    signerEmail() {
      return this.signer?.email;
    },
    signerPhone() {
      return this.signer?.phone;
    },
    haveRequestId() {
      return this.requestId;
    },
  },
  methods: {
    signWithEnter (keyCode: any) {      
      if (keyCode === 13) {
        if (!this.user) {
            if (this.password !== '') this.getUserByPasswordAndSign()
        } else if (this.signer.documentType !== '') {
            if (this.document !== '') this.signDocument()
        } else {
            if (this.document !== '' && this.name !== '') this.signDocument()
        }
      }
    },
    closeModal () {
      this.canDismiss = true
      this.isOpenModal = false;
      this.isAcceptReject = false
      this.password = ""
    },
    openModal(action: boolean) {
      this.canDismiss = false
      if (action) {
        this.isOpenModal = true 
        this.rejectDocument = false
      } else {
        this.isOpenModal = true 
        this.rejectDocument = true
      }
    },
    setPosition(position: any) {
      if (position)
        this.geolocation = `${position.coords.latitude},${position.coords.longitude}`;
      else this.geolocation = "";
    },
    formatedDate(item: any) {
      return dayjs(item.createdAt).format("DD/MM/YYYY, HH:mm:ss");
    },
    formatedTitle(text: any) {
      var loweredText = text.toLowerCase()
      var words = loweredText.split(" ")
      for (var a = 0; a < words.length; a++) {
        var w = words[a]
        var firstLetter = w[0] 

        if( w.length > 2){ 
           w = firstLetter.toUpperCase() + w.slice(1)
        } else {
           w = firstLetter + w.slice(1)
        }

        words[a] = w
      }
      return words.join(" ")
    },
    titleStatus(item: any) {
      let resp = this.papersSignature.filter((x: any) => x.id === item.paperSignatureId)
      if (item?.status == 1 && item.partSigned) {
        return "Pendente"
      }
      if (item?.status == 1 ) {
        return "Pendente"
      }
      if (item?.status == 2) {
        return this.formatedTitle(resp[0].action)
      }
      if (item?.status == 3) {
        return "Cancelado"
      }
      if (item?.status == 5) {
        return "Observador"
      }
      return "Recusado"
    },
    titleStatusSigner(item: any) {
      if (item?.status == 2) {
        return "Assinado"
      }
      if (item?.status == 3) {
        return "Cancelado"
      }
      if (item?.status == 4) {
        return "Recusado"
      }
      if (item?.status == 5) {
        return "Observador"
      }

      return "Assinar"
    },
    titleBtnSigner(item: any) {
      let resp = ""
      this.papersSignature.forEach((paper: any) => {
        if (paper.id === item) resp = paper.name
      })
      return resp
    },
    documentSignatureStatus(item: any) {
      return item?.status
    },
    lastIndex(index: any) {
      return index == this.subscribers.length - 2
    },
    onClickSigner(item: any) {
      if (item.status === 1) {
        this.isOpenModal = true
      }
    },
    async getUserByPasswordAndSign() {
      const request: CpfCommandRequest = {
        password: this.password
      }
      const data = await UserService
        .getInstance()
        .getByPassword(request)
        
      this.document = data.cpf
      await this.signDocument()
    },
    async loadInformation() {
      this.loading = true
      try {
        this.services = EnvelopeService.getInstance()
        this.paperSignatureServices = PaperSignatureService.getInstance()

        this.hashId = this.$route?.params?.hashId
        this.requestSignId = this.$route?.params?.requestSignId

        if (!this.$route?.query?.key){
          if (this.requestSignId != undefined) {
            this.passEnvelopeId = this.$route?.params?.passEnvelopeId

            const redirect = await this.services.getRedirectToSign(this.passEnvelopeId, this.requestSignId)
            window.open(redirect.redirectLink, "_self")
          } else {
            const redirect = await this.services.getRedirect(this.hashId)
            window.open(redirect.redirectLink, "_self")
          }
        }
        this.requestId = this.$route?.query?.request

        this.user = this.$route?.query.user?.toString().toLowerCase() == 'true'
        const key = this.$route?.query?.key?.toString()

        await TokenService.setToken(key!)

        const data = await this.services?.listSign(this.hashId, this.requestId)
        this.requestId = data.requestId
        this.title = data.title
        this.folderPath = data.folderPath
        this.subscribers = data.signatureRequests
        this.signer = this.subscribers[0]
        this.isOrder = data.isOrder
        this.dateSendEnvelope = this.formatedDate(data.envelopes[data.envelopes.length - 1]).replace(",", " às ")        

        this.historico = (await this.services.historicoEnvelope(
          this.hashId
        )) as any

        this.papersSignature = (await this.paperSignatureServices.listPapersSignature()) as any

        ArchiveService.getInstance().getArchive(data.archive).catch(error => { 
          if (error.message === 'file not found') {
            // const msgOne = 'Aguarde alguns instantes pois todos os signatários já finalizaram suas assinaturas e o Certificata está gerando o protocolo de finalização.'
            // const msgTwo = 'O documento será exibido assim que o processo estiver concluído.'

            // Modal.info({
            //  title: 'Importante',
            //  content: h('div', { }, [
            //    h('p', msgOne),
            //    h('p', msgTwo)
            //  ]),
            //  onOk: () => {
            //    document.location.reload()
            //  },
            //  centered: true
            // })

            setTimeout(async () => {
              // Modal.destroyAll() 
              this.loadInformation()             
            }, 30000)
          }          
        })
         
        this.fileUrl = data.archive
        this.loading = false
      } catch(err) {
        this.loading = false
      }
    },
    async reject() {
      try {
        await this.services?.signRejectRequest({rejectedJustification: this.document, signatureRequestId: this.requestId});
        this.isOpenModal = false
        const toast = await toastController.create({
          message: "Documento recusado",
          icon: checkmarkCircleOutline,
          animated: true,
          duration: 4000,
          position: "bottom",
          color: "success",
        })

        await toast.present()
        await this.loadInformation()
      } catch (err) {
        console.log(err)
      }
    },
    async signDocument() {
      const signRequest = {
        envelopeHash: this.hashId,
        signatureRequestId: parseInt(this.requestId),
        identificationName: this.name,
        identificationDocument: this.document,
        geolocalization: this.geolocation,
      } as any
      try {
        if (signRequest.identificationName !== '') {
          var response = await this.services?.proofOfLife(signRequest)
          if (response.valid) {
            this.isOpenModal = false
            await this.services?.signRequest(signRequest)

            const toast = await toastController.create({
              message: "Documento assinado com sucesso!",
              icon: checkmarkCircleOutline,
              animated: true,
              duration: 4000,
              position: "bottom",
              color: "success",
            })

            await toast.present()
            await this.loadInformation()
          } else {
            const toast = await toastController.create({
              message: "Erro na Assinatura. Os dados informados estão incorretos!",
              icon: closeCircle,
              animated: true,
              duration: 4000,
              position: "bottom",
              color: "danger",
            })

            await toast.present()
          }
        } else {
            this.isOpenModal = false
            await this.services?.signRequest(signRequest)

            const toast = await toastController.create({
              message: "Documento assinado com sucesso!",
              icon: checkmarkCircleOutline,
              animated: true,
              duration: 4000,
              position: "bottom",
              color: "success",
            })

            await toast.present()
            await this.loadInformation()
        }
        
      } catch (err) {
        console.log(err)
      }
    },
    async markWatcherView() {
      if (this.signer.status === 5) {
        await this.services?.markWatcherView(this.hashId, this.requestId, this.signer.partName)
      }
    },
    showViewer () {
      if (this.isViewer) {
        this.isViewer = false
        this.isInfo = true
      } else {
        this.isViewer = true
        this.isInfo = false
      }
    },
  },
});
