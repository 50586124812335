import Vuex from "vuex";

import autenticador from "../login/autenticador/store/index";
import cadastros from "@/views/login/cadastros/store";
import sections from "@/views/sign/sections/store";

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",

  modules: {
    autenticador,
    cadastros,
    sections,
  },
});
