
import { defineComponent } from "vue";
import { IonCheckbox } from "@ionic/vue";

export default defineComponent({
  name: "SignCheckbox",
  components: {
    IonCheckbox,
  },

  props: {
    name: String,
  },
});
