import { HTTPBaseService } from "./HTTPBaseService";

export class ArchiveService extends HTTPBaseService {
  private static classInstance?: ArchiveService;

  constructor() {
    super(process.env.VUE_APP_ROOT_API_SHAR);
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new ArchiveService();
    }

    return this.classInstance;
  }

  public async getArchive(url: string): Promise<any> {
    const { data } = (await this.instance.get(url)) as any;
    return data;
  }
}
