
  import { defineComponent } from "vue"
 
  export default defineComponent({
    name: "headerMain",
    components: { },  
    data: () => ({ }),  
    computed: { },  
    methods: {
      getLogo(): string {
        return require("@/images/logo-certificata.png");
      },
      redirectApp() {
        const url = process.env.VUE_APP_ROOT_PRODUCT_URL
        window.open(url, '_self')?.focus()
      }
    },
  })
