
import { defineComponent } from "vue";
import { IonInput, IonIcon, IonTextarea } from "@ionic/vue";
import { eye, eyeOffOutline } from "ionicons/icons";
import Mask from "../utils/mask";

export default defineComponent({
  name: "SignInput",
  components: {
    IonInput,
    IonIcon,
    IonTextarea
  },
  setup() {
    return {
      eye,
      eyeOffOutline,
    };
  },

  props: {
    name: String,
    text: String,
    type: String,
    newValue: String,
    hasIcon: Boolean,
    required: Boolean,
    hasSubtitle: Boolean,
    subtitle: String,
    isCpf: Boolean,
    isCnpj: Boolean,
    isPhone: Boolean,
    isCep: Boolean,
    isTextArea: Boolean,
    isDomain: Boolean,
    disabled: Boolean,
  },

  data: () => ({
    value: "" as string,
    show: false,
  }),

  watch: {
    value() {
      if (this.isCpf) {
        this.value = Mask(this.value, "###.###.###-##");
      }

      if (this.isCnpj) {
        this.value = Mask(this.value, "##.###.###/####-##");
      }

      if (this.isPhone) {
        this.value = Mask(this.value, "(##)#####-####");
      }

      if (this.isCep) {
        this.value = Mask(this.value, "#####-###");
      }

      this.$emit("value", this.value);
    },

    newValue() {
      this.value = this.newValue!;
    },
  },

  computed: {
    iconType() {
      return this.type === "password" && this.show ? "text" : this.type;
    },

    maxLength() {
      if (this.isCnpj) {
        return 18;
      }

      if (this.isPhone) {
        return 14;
      }

      if (this.isCep) {
        return 10;
      }

      return this.isCpf ? 14 : -1;
    },
  },
});
