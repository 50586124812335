import axios, { AxiosResponse } from "axios";
import moment from "moment";
import { HTTPBaseService } from "./HTTPBaseService";
import { CpfCommandResponse } from "./responses/CpfCommandResponse";
import { TokenService } from "./TokenService";
import { CpfCommandRequest } from "./requests/CpfCommandRequest";

export class UserService extends HTTPBaseService {
  private static classInstance?: UserService;

  constructor() {
    super(process.env.VUE_APP_ROOT_API_SHAR);
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new UserService();
    }
    return this.classInstance;
  }

  public getByPassword = async (
    request: CpfCommandRequest
  ): Promise<CpfCommandResponse> => {
    const response: AxiosResponse<CpfCommandResponse> =
      await this.instance.post<CpfCommandResponse>(
        "/core/api/v1/users/cpf",
        request
      );
      
    return response.data;
  }
  
  public async verify(): Promise<void> {
    const instance = axios.create({
      baseURL: this.baseURL,
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
      },
    });
    const response = await instance.put("/core/api/v1/users/verify", null, {
      params: {
        ValidateDate: moment().format(),
      },
    });
    if (response.status === 401) throw new Error(response.statusText);
  }
}
