import { AxiosInstance } from "axios";
import { HTTPBaseService } from "./HTTPBaseService";
import { ListEnvelopeResponse } from "@/services/responses/ListEnvelopeResponse";
import { EnvelopeInfoResponse } from "./responses/EnvelopeInfoResponse";
import { SignResponse } from "./responses/SignRespose";
import { SignRequest } from "./requests/SignRequest";
import { RedirectLinkResponse } from "./responses/RedirectLinkResponse";

export class EnvelopeService extends HTTPBaseService {
  private static classInstance?: EnvelopeService;

  constructor() {
    super(process.env.VUE_APP_ROOT_API_SHAR);
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new EnvelopeService();
    }

    return this.classInstance;
  }

  public async getRedirect(hashId: any){
    const { data } = await this.instance.get<RedirectLinkResponse>(
      `/redirect/api/v1/redirectlinks/${hashId}`
    );
    return data;
  }
  public async getRedirectToSign(
    passEnvelopeId: any,
    requestSignId: number){
    const { data } = await this.instance.get<RedirectLinkResponse>(
      `/redirect/api/v1/redirectlinks/tosign/${passEnvelopeId}/${requestSignId}`
    );
    return data;
  }
  public async listEnvelopes(
    folderId: number | null,
    pageNumber: number,
    pageSize: number
  ): Promise<ListEnvelopeResponse> {
    const { data } = await this.instance.get<ListEnvelopeResponse>(
      "/core/api/v1/envelopes",
      { params: { folderId, pageNumber, pageSize } }
    );
    return data;
  }

  public async listSign(
    hashId: string,
    request: number | null
  ): Promise<SignResponse> {
    const { data } = await this.instance.get<SignResponse>(
      `/core/api/v1/envelopes/${hashId}`,
      { params: { signatureRequestId: request } }
    );
    return data;
  }

  public async listInfoEnvelope(
    folderId: number | null = null,
    envelopeId: number | null = null
  ): Promise<EnvelopeInfoResponse[]> {
    const { data } = await this.instance.get<EnvelopeInfoResponse[]>(
      "/core/api/v1/envelopes/infos",
      { params: { folderId, envelopeId } }
    );

    return data;
  }

  public async historicoEnvelope(
    hashId: string | null = null
  ): Promise<EnvelopeInfoResponse[]> {
    const { data } = (await this.instance.get<EnvelopeInfoResponse[]>(
      "/core/api/v1/envelopes/infos",
      { params: { hashId } }
    )) as any;

    return data;
  }

  public async createEnvelope(formData: FormData) {
    const response = await this.instance.postForm(
      "/core/api/v1/envelopes",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  public async signRequest(request: SignRequest): Promise<void> {
    await this.instance.post<SignRequest>(
      "/core/api/v1/envelopes/sign",
      request
    );
  }

  public async signRejectRequest(reject:{signatureRequestId: number, rejectedJustification:string}): Promise<void> {
    await this.instance.put(
      `/core/api/v1/envelopes/sign/rejected`, reject
    );
  }

  public async markWatcherView(
    hashId: string | null = null,
    userId: string | null = null,
    userName: string | null = null
  ): Promise<void> {
    (await this.instance.get<void>(
      "/core/api/v1/envelopes/observer/view",
      { params: { hashId, userId, userName } }
    )) as any;
  }

  public async proofOfLife(request: any): Promise<any> {
    const {data} = await this.instance.post<any>(
      "/core/api/v1/envelopes/proofoflife", request
    )
    return data
  }

}
