const { GENERIC_MUTATION } = types;
import { ActionTree } from "vuex";
import { EnvelopeService } from "@/services/EnvelopeService";

import types from "./types";

const setOpenSidebar = ({ commit, state }: any) => {
  const { openSidebar } = state;

  commit(GENERIC_MUTATION, {
    property: "openSidebar",
    value: !openSidebar,
  });
};

const setEnvelops = ({ commit }: any, envelopes: any) => {
  commit(GENERIC_MUTATION, {
    property: "envelopes",
    value: envelopes,
  });
};

const fetchEnvelops = async (
  { dispatch }: any,
  { folderId, page, size }: any
) => {
  const envelopes = (await EnvelopeService.getInstance().listEnvelopes(
    folderId,
    page,
    size
  )) as any;

  dispatch("setEnvelops", envelopes);
  dispatch("setFolderId", folderId);
};

const fetchHistoricoEnvelops = async (
  { commit, dispatch }: any,
  { folderId, envelopeId }: any
) => {
  dispatch("setOpenHistorico", true);
  const historico = await EnvelopeService.getInstance().listInfoEnvelope(
    folderId,
    envelopeId
  );

  commit(GENERIC_MUTATION, {
    property: "historicoEnvelopes",
    value: historico,
  });
};

const setFolderId = ({ commit }: any, folderId: any) => {
  commit(GENERIC_MUTATION, {
    property: "folderId",
    value: folderId,
  });
};

const setOpenHistorico = ({ commit }: any, value: boolean) => {
  commit(GENERIC_MUTATION, {
    property: "openHistorico",
    value,
  });
};

export default <ActionTree<any, any>>{
  setOpenSidebar,
  setEnvelops,
  setFolderId,
  fetchEnvelops,
  fetchHistoricoEnvelops,
  setOpenHistorico,
};
