
  import { defineComponent, onMounted,onBeforeUpdate, onUpdated, ref, h } from "vue"
  import { Modal } from 'ant-design-vue'
  import {
    LeftCircleOutlined,
    RightCircleOutlined,
    EyeOutlined,
    DownloadOutlined
} from "@ant-design/icons-vue"
  
  import * as pdfjs from 'pdfjs-dist/build/pdf.js'
  import * as pdfjsWorker from 'pdfjs-dist/build/pdf.worker.js'

  export default defineComponent({
    name: "PDFViewer",
    props: {
      url: String,
      isMobile: Boolean,
      titleFile: String
    }, 
    components: {
      EyeOutlined,
      DownloadOutlined
    },
    setup (props) {
      // const isMobile = ref(false)
      const downDoc = async (url: any, title: any) => {
        const res = await fetch(url, {
            mode: "cors",
            method: "GET",
            cache: "force-cache",
            headers: {
            Accept: "application/pdf",
          },
        })
        
        if (res.status === 200) {
          // Create blob
          const blob = await res.blob()
          // Create an invisible A element
          const a = document.createElement("a")
          a.style.display = "none"
          document.body.appendChild(a)
          // Set the HREF to a Blob representation of the data to be downloaded
          a.href = window.URL.createObjectURL(blob)
          const fileName = title.replace(/[.,/#!$%^&*;:{}=\-_`~()\s]/g, ' ')
          // Use download attribute to set set desired file name
          a.setAttribute("download", fileName)
          // Trigger the download by simulating click
          a.click()
          // Cleanup
          window.URL.revokeObjectURL(a.href)
          document.body.removeChild(a)
        } else {
            const msgOne = 'Aguarde alguns instantes pois o Certificata está gerando o protocolo de finalização.'
            const msgTwo = 'O documento estara disponível assim que o processo estiver concluído.'

            Modal.info({
              title: 'Importante',
              content: h('div', { }, [
                h('p', msgOne),
                h('p', msgTwo)
              ]),
              centered: true,
              onOk: () => {
                document.location.reload()
              }
            })

            setTimeout(async () => {
              Modal.destroyAll()
              document.location.reload()             
            }, 40000)
        }
      } 
      return { 
        downDoc
      }
    },
    /*components: {
      LeftCircleOutlined,
      RightCircleOutlined
    },
    setup (props) {
      // pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
      pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.5.141/pdf.worker.min.js';
      const myCanvas = ref(null)
      let pdfObj: any = null
      const pdfEnd = ref(0)
      const curPage = ref(1) 
      const loaddingPage = ref<boolean>(true)
           
      const prePage = () => {
        curPage.value--
        getPage(pdfObj, curPage.value)
      }

      const nextPage = () => {
        curPage.value++
        getPage(pdfObj, curPage.value)
      }
      
      const getPage = (pdf: any, pageNumber: any) => {
        if (pageNumber <= 0) {
          curPage.value = 1
        } else if (pageNumber > pdf.numPages) {
          curPage.value = pdf.numPages
        } else {
          pdf.getPage(pageNumber).then((page: any) => {            
            let scale = 2.0;
            let viewport = page.getViewport({ scale: scale })
            let outputScale = window.devicePixelRatio || 1

            let canvas: any = document.getElementById('docContainer')
            let ctx = canvas.getContext("2d")

            canvas.width = Math.floor(viewport.width * outputScale);
            canvas.height = Math.floor(viewport.height * outputScale);
           
            let transform = outputScale !== 1
              ? [outputScale, 0, 0, outputScale, 0, 0]
              : null

            let renderContext = {
              canvasContext: ctx,
              transform: transform,
              viewport: viewport
            };

            page.render(renderContext).promise.then()
          })
        }
      }

      const previewDocument = (urlFile: any) => {
        let loadingTask = pdfjs.getDocument(urlFile)
        loadingTask.promise.then((doc: any) => {
          pdfObj = doc
          pdfEnd.value = doc.numPages
          getPage(doc, curPage.value)
          // // pdfViewer.setDocument(pdfDocument);
        })
      }

      onMounted( () => {
        if (props.url !== null) {
          loaddingPage.value = false
          previewDocument(props.url)
        } 
      })

      onUpdated ( () => {
        if (loaddingPage.value) {
          loaddingPage.value = false
          previewDocument(props.url)          
        }        
      })

      return {
        pdfObj,
        pdfEnd,
        curPage,
        prePage,
        nextPage,
        getPage,
        previewDocument        
      }
    },*/
    
  })
