
import { defineComponent } from "vue";
import { IonInput, IonIcon } from "@ionic/vue";
import { eye, eyeOffOutline } from "ionicons/icons";

export default defineComponent({
  name: "SignDropdown",
  components: {
    IonInput,
    IonIcon,
  },
  setup() {
    return {
      eye,
      eyeOffOutline,
    };
  },

  props: {
    text: String,
    required: Boolean,
    items: Array,
  },

  data: () => ({
    value: null as any,
    show: false,
    dropdown: false,
  }),

  created() {
    this.value = null;

    this.$emit("value", this.value);
  },

  methods: {
    selecionar(item: any) {
      this.value = item.label;
      this.dropdown = false;
      this.$emit("value", item);
    },

    resetState() {
      this.value = null;
    },
  },
});
